#coverage-perils .mat-raised-button {
    display: block;
    min-width: 100%;
    background-color: rgb(25, 25, 121);
    color: rgb(255, 255, 255);
}

#coverage-perils table {
    width: 100%;
    border-style: solid;
    border-width: 20px;
    border-color: rgb(255, 255, 255);
    vertical-align: middle;
}

#coverage-perils .mat-column-coverage {
    /* width: 70%; */
    vertical-align: middle;
}

#coverage-perils .mat-column-premAmount,
#coverage-perils .mat-column-amountCovered,
#coverage-perils .mat-column-commAmt,
#coverage-perils .mat-column-serviceFeeAmt {
    text-align: right;
    vertical-align: middle;
    padding-right: 23px;
    min-width: 130px;
    max-width: 150px;
}

#coverage-perils .mat-column-premRt,
#coverage-perils .mat-column-commRt,
#coverage-perils .mat-column-serviceFeeRt {
    text-align: right;
    vertical-align: middle;
    padding-right: 23px;
    min-width: 100px;
    max-width: 120px;
}

#coverage-perils .mat-column-premRt .mat-form-field-flex,
#coverage-perils .mat-column-amountCovered .mat-form-field-flex,
#coverage-perils .mat-column-premAmount .mat-form-field-flex,
#coverage-perils .mat-column-commRt .mat-form-field-flex,
#coverage-perils .mat-column-serviceFeeRt .mat-form-field-flex {
    background: transparent;
}

#coverage-perils .mat-table .mat-form-field-flex .mat-input-element,
#coverage-perils .mat-table .mat-form-field-flex .mat-select,
#coverage-perils .mat-table .mat-form-field-flex .mat-select-value-text {
    font-weight: normal !important;
    background-color: #F2C94C;
}

#coverage-perils .mat-column-perilName {
    min-width: 150px;
}

#coverage-perils .mat-column-itemNo {
    min-width: 104px;
}

#coverage-perils .mat-header-cell {
    font-weight: 900;
    vertical-align: middle;
    border-bottom-style: none;
}

#coverage-perils .mat-column-amountCovered .mat-sort-header-container,
#coverage-perils .mat-column-premAmount .mat-sort-header-container,
#coverage-perils .mat-column-premRt .mat-sort-header-container,
#coverage-perils .mat-column-commRt .mat-sort-header-container,
#coverage-perils .mat-column-commAmt .mat-sort-header-container,
#coverage-perils .mat-column-serviceFeeRt .mat-sort-header-container {
    display: flex;
    padding: 21px 0;
    direction: rtl;
}

#coverage-perils .mat-row:nth-child(odd) {
    background-color: rgba(241, 241, 241, 0.671);
}

#coverage-perils td.mat-cell {
    border-bottom-style: none;
}

#coverage-perils .mat-table {
    box-shadow: none;
}

#coverage-perils input,
#coverage-perils mat-select {
    font-weight: 600;
}

#coverage-perils .peril-title {
    font-size: 24px;
    font-family: ProximaNova;
    font-weight: 600;
    margin-top: 75px;
}

#coverage-perils table.mat-table {
    border-spacing: 0;
    width: 100%;
}

#coverage-perils .mat-card-content .col.header {
    display: flex;
    justify-content: flex-start;
    flex-wrap: wrap;
}

#coverage-perils .form.no-border {
    border-bottom: 0px;
}

#coverage-perils .mat-card-content .col.header .field .field-desc,
#coverage-perils .mat-card-content .col.header .field .field-value {
    font-size: 16px;
    font-weight: normal;
    vertical-align: middle;
    padding: 0 0px 6px 25px;
    min-width: 225px;
}

#coverage-perils .mat-card-content .col.header .field .field-value {
    font-weight: 600;
}

#coverage-perils .mat-card {
    margin-bottom: 20px;
}

#coverage-perils .mat-card.white {
    color: black;
    background-color: white;
}

#coverage-perils .mat-card.blue {
    color: white;
    background-color: #1B1464;
}

#policy-header-details-form {
    margin-bottom: 30px;
}
#policy-header-details-form ~ .form .bold {
    font-weight: bold;
}
#policy-header-details-form > .form-loader .form-loader-container {
    margin-top: 70px;
    margin-bottom: 30px;
    padding-bottom: 60px;
    border-bottom: 1px solid rgba(0, 0, 0, 0.08);
}
#policy-header-details-form .policy-header-details-form-msg.top {
    display: none;
}

#coverage-perils .form .policy-header-form {
    border-bottom: none !important; 
}

#coverage-perils .form-title {
    display: flex;
    justify-content: space-between;
    border-top: 1px solid rgba(0, 0, 0, 0.08);
    margin-top: 48px;
}

#coverage-perils .form-title h2 {
    font-family: Arial,Calibri,sans-serif;
    font-size: 22px;
    letter-spacing: .04em;
    color: #000;
    text-transform: uppercase;
    margin-bottom: 24px;
    margin-top: 36px;
}

#coverage-perils .product-details .summary > .box-1{
    margin-bottom: 15px;
}

#coverage-perils .product-details .summary.premium-charges .box-1 .box-holder.style-2 .detail {
    width: 23%;
}

#coverage-perils .product-details .summary.premium-charges .box-2 .box-holder > .detail {
    margin-right: 2%;
    width: 23%;
}

@media screen and (max-width: 640px) {
    #coverage-perils .mat-card-content .col.header .field {
        flex: 48%;
    }
    #coverage-perils .mat-card-content .col.header .field {
        border: none;
        padding: 0;
    }
    #coverage-perils .product-details .summary .box-1 .box-holder.style-2 .detail,
    #coverage-perils .product-details .summary.premium-charges .box-1 .box-holder.style-2 .detail {
        width: 48%;
    }
}

@media screen and (max-width: 767px) {
    #policy-header-details-form .policy-header-details-form-msg.top {
        display: block;
    }
    #policy-header-details-form .policy-header-details-form-msg.bottom {
        display: none;
    }
    #coverage-perils .product-details .summary.premium-charges .box-1 .box-holder.style-2 .detail {
        width: 31%;
    }
}


#coverage-perils .mat-card-content .col.header .field.selected-peril .field-desc {
    font-weight: normal;
    vertical-align: middle;
    padding: 0 20px 6px 0px;
    min-width: 200px;
    text-align: right;
}

#coverage-perils .mat-card-content .col.header .field.selected-peril .field-value {
    font-weight: normal;
    vertical-align: middle;
    padding: 0 20px 6px 0px;
    min-width: 200px;
    font-weight: 600;
    text-align: right;
}

#coverage-perils .mat-card-content .col.header .field.peril-name .field-desc {
    font-weight: normal;
    vertical-align: middle;
    padding: 0 0px 6px 20px;
    min-width: 200px;
}

#coverage-perils .mat-card-content .col.header .field.peril-name .field-value {
    font-weight: normal;
    vertical-align: middle;
    padding: 0 0px 6px 20px;
    min-width: 200px;
    font-weight: 600;
}

#coverage-perils table.mat-table tbody tr:last-child {
    font-weight: bold;
}

#coverage-perils .edit-btn {
    float: right;
    color: #B11116;
}

#coverage-perils .edit-btn:hover {
    text-decoration: underline;
    cursor: pointer;
}

#coverage-perils .mat-card-content .field:first-child {
    color: #1B1464;
}

#coverage-perils .mat-table .mat-form-field-wrapper>.mat-form-field-flex {
    border: none;
}

#coverage-perils .edit-container {
    display: flex;
    padding: 5px;
    flex-direction: row-reverse;
}

#coverage-perils .box-wrap {
    padding: 0;
}

#coverage-perils .show-comp-btn {
    text-align: center
}

#coverage-perils .show-comp-btn .btn{
    width: max-content;
}

#coverage-perils .show-comp-btn .mat-form-field-flex {
    padding: 0 imp !important;
}

#coverage-perils .show-comp-btn .mat-form-field-flex {
    border: none;
    background: transparent;
}

#coverage-perils .show-comp-btn .mat-form-field-flex .mat-form-field-infix {
    padding: 0;
    border: none;
}

#coverage-perils .show-comp-btn .mat-form-field-flex .mat-form-field-infix input {
    display: none;
}

#coverage-perils .number-header .mat-sort-header-content {
    text-align: right;
}

#coverage-perils .table-box {
    overflow-x: auto;
}

.mat-tooltip {
    white-space: pre-line;
    font-family: Roboto, "Helvetica Neue", sans-serif;
    font-size: 16px
}

#coverage-perils ::-webkit-scrollbar {
    -webkit-appearance: none;
}

#coverage-perils ::-webkit-scrollbar:vertical {
    width: 12px;
}

#coverage-perils ::-webkit-scrollbar:horizontal {
    height: 12px;
}

#coverage-perils ::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, .5);
    border-radius: 10px;
    border: 2px solid #ffffff;
}

#coverage-perils ::-webkit-scrollbar-track {
    border-radius: 10px;
    background-color: #ffffff;
}

@media screen and (max-width: 480px) {
    #coverage-perils .show-comp-btn .btn {
        width: 100%;
    }
    #policy-header-details-form .form-loader .form-loader-container {
        margin-top: 90px;
    }
}

.cdk-viewport {
    min-height: 48px;
    max-height: 256px;
    overflow-x: hidden;
}

#coverage-perils input[type=number].noArrow::-webkit-outer-spin-button,
#coverage-perils input[type=number].noArrow::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
}

#coverage-perils input[type=number].noArrow {
    -moz-appearance: textfield;
}